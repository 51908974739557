<template>
  <div v-if="token" class="buyer-wrapper">
    <!-- Navbar -->
    <buyer-navbar />

    <!-- Content -->
    <b-container fluid class="mt-3">
      <Content></Content>
    </b-container>

    <!-- Bottom Navbar -->
    <buyer-bottom-navbar />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BuyerNavbar from "../../components/navbars/buyer/BuyerNavbar.vue";
import BuyerBottomNavbar from "../../components/navbars/buyer/BuyerBottomNavbar.vue";

export default {
  components: { BuyerNavbar, BuyerBottomNavbar },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
};
</script>

<style scoped>
.container,
.container-fluid {
  margin-bottom: 75px;
}
</style>
