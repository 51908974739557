<template>
  <!-- Using merchant navbar since its the same for now-->
  <merchant-navbar />
</template>

<script>
import MerchantNavbar from "../merchant/MerchantNavbar.vue";
export default {
  components: { MerchantNavbar },
};
</script>
